/* eslint-disable react/prop-types */
import loadable from '@loadable/component';
import classnames from 'classnames';
import React from 'react';
import { FormattedMessage } from 'react-intl';

import { ReactComponent as DeleteIcon } from '../../images/icons/delete.svg';
import { ReactComponent as RecentlyViewedProductsIcon } from '../../images/icons/recently_viewed.svg';

import styles from './RecentlyViewedProducts.module.scss';
import { Link } from 'react-router-dom';
import { ARD_PROFILE_RECENT_PRODUCTS } from '../../utils/appRoutes.definitions';
import { getPagePath } from '../../utils/appRoutes';
import { useRecentlyViewedProducts } from '../../utils/useRecentlyViewedProducts';
//todo: use unified method to set auth headers for all required requests
const ProductCard = loadable(() => import('../ProductCard/ProductCard'));
const Sliders = loadable(() => import('../Sliders/Sliders'));

const SeeAllCard = () => (
  <article className={styles.seeAll}>
    <Link to={getPagePath(ARD_PROFILE_RECENT_PRODUCTS)}>
      <h3>
        <FormattedMessage
          id="footer.allRecentView"
          defaultMessage="Here we will show the products you viewed recently"
        />
      </h3>
    </Link>
  </article>
);

const RecentlyViewedProducts = () => {
  const { recentlyViewedProducts, deleteProducts } = useRecentlyViewedProducts();

  if (!recentlyViewedProducts?.length) {
    return null;
  }

  return (
    <section
      data-testid="recently-viewed-products"
      className={classnames(styles.root)}
      key={`recentlyViewedProducts_${recentlyViewedProducts.length}`}
    >
      <div className="container">
        <div className="row">
          <div className={classnames('col-12-xs', styles.header)}>
            <RecentlyViewedProductsIcon className={styles.recentlyViewedProductsIcon} />
            <span>
              <FormattedMessage default="Recently" id="profile.recent" />
            </span>
            <FormattedMessage default="viewed" id="profile.view" />
          </div>
        </div>
        <Sliders
          classList={{
            card: classnames(styles.sliderCard),
            root: styles.slidersRoot,
            color: styles.navBackground,
            slides: classnames(styles.itemsContainer),
            seeAll: styles.seeAll,
          }}
          gap={15}
          viewAllAsCard
          SeeAllCard={SeeAllCard}
          viewAll
        >
          {recentlyViewedProducts.map((product) => (
            <ProductCard
              classList={{ root: styles.boxShadow }}
              key={`rvp-section-${product.productId}`}
              product={product}
              isProfile
            />
          ))}
        </Sliders>
        <div className={classnames('row', styles.deleteListButton)}>
          <div className="col-12-xs">
            {recentlyViewedProducts.length > 0 && (
              <button className={styles.delete} onClick={deleteProducts}>
                <DeleteIcon className={styles.deleteIcon} />
                <FormattedMessage id="common.deleteList" defaultMessage="Verwijders lijst" />
              </button>
            )}
          </div>
        </div>
      </div>
    </section>
  );
};

export default RecentlyViewedProducts;
